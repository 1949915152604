
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({ name: 'TitleSynopsis' })
export default class TitleSynopsis extends Vue {
	@Prop({ default: '' }) shortDescription: string;
	@Prop({ default: '' }) originalShortDescription: string;
	@Prop({ default: true }) showSubheading: boolean;

	showOriginal = false;

	get synopsis(): string {
		return this.showOriginal ? this.originalShortDescription : this.shortDescription;
	}
}
